<template>
 
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        
        hide-no-data
        hide-selected
        item-text="stateName"
        item-value="stateID"
        label="State"
        placeholder="Enter State Name"
        
        return-object
      ></v-autocomplete>

</template>

<script>
  export default {
    name: 'StateAutocomplete',
     data: () => ({
      descriptionLimit: 60,
      states: [],
      isLoading: false,
      model: null,
      search: null,
    }),
     methods: {
       resetstates(){
         this.search = ''
         

       }
     },

    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.states.map(state => {
          const stateName = state.stateName.length > this.descriptionLimit
            ? state.stateName.slice(0, this.descriptionLimit) + '...'
            : state.stateName

          return Object.assign({}, state, { stateName })
        })
      },
    },

    watch: {
      model(val){
        let view = this
        if (val.stateID){
          view.$emit('selectstate', val)
          console.log(val.stateID)

        }
        
      },
      search (val) {
        
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        fetch('https://dev02.competiscan.com:5053/statesearch')
          .then(res => res.json())
          .then(res => {
            const {  states } = res
            this.states = res.states
            console.log(this.states)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
  }
</script>


