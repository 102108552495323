<template>
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-text="companyName"
        item-value="companyID"
        label="Companies"
        placeholder="Enter Company Name"
        return-object
      ></v-autocomplete>

</template>

<script>
  export default {
    name: 'CompanyAutocomplete',
     data: () => ({
      descriptionLimit: 60,
      companies: [],
      isLoading: false,
      model: null,
      search: null,
    }),
     methods: {
       resetcompany(){
        
         this.search = ''
         

       }
     },

    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.companies.map(company => {
          const companyName = company.companyName.length > this.descriptionLimit
            ? company.companyName.slice(0, this.descriptionLimit) + '...'
            : company.companyName

          return Object.assign({}, company, { companyName })
        })
      },
    },
    

    watch: {
      model(val){
        let view = this
        if (val.companyID){
          view.$emit('selectcompany', val)
          console.log(val.companyID)

        }
        
      },
      search (val) {
        let company_url = this.$url("COMPANYURL") 
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        fetch(company_url)
          .then(res => res.json())
          .then(res => {
            const {  companies } = res
            this.companies = res.company
            console.log(this.companies)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
  }
</script>


